import axios from "axios";
import { createXApiKey } from "~/infra/http/XApiKey";

export const useAxios = ({ useV2 = false } = {}) => {
  const runtime = useRuntimeConfig();

  const xApiKey = useXApiKey();
  const authStore = useAuthStore();
  const { theme } = useTheme();
  const toast = useToast();

  const axiosHttpClient = axios.create({
    baseURL: useV2
      ? runtime.public.VUE_APP_URL_API_V2
      : runtime.public.VUE_APP_URL_API,
  });

  axiosHttpClient.defaults.headers.common.Authorization =
    `Bearer ${authStore.access_token}`;
  axiosHttpClient.defaults.headers.common["x-api-key"] = xApiKey.value;

  axiosHttpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 403) {
        console.log(error?.response?.data);

        if (
          error?.response?.data?.message === "Mailing já está na fila de envio."
        ) {
          return Promise.reject("Mailing já está na fila de envio.");
        }

        if (
          error?.response?.data?.message ===
          "Usuário não está autenticado, faça o login novamente."
        ) {
          window.location.href = `/${theme.value}/login`;
          return Promise.reject(
            "Usuário não está autenticado, faça o login novamente.",
          );
        }

        if (
          error?.response?.data?.message ===
          "Não é possível alterar uma venda realizada através deste PDV."
        ) {
          return Promise.reject(error.response.data.message);
        }

        xApiKey.value = createXApiKey().generateToken();
        axiosHttpClient.defaults.headers.common["x-api-key"] = xApiKey.value;
        originalRequest.headers["x-api-key"] = xApiKey.value;

        return axiosHttpClient(originalRequest);
      }

      if (error.response.status === 401) {
        if (
          error.response.data.message ===
          "Sua sessão expirou por inatividade. Por favor, realize o login novamente."
        ) {
          toast.add({
            color: "red",
            description:
              "Token expirado. Por favor, realize o login novamente.",
          });
          Promise.reject(error.response.data.message);
          window.location.href = `/${theme.value}/login`;
        }
      }

      return Promise.reject(error);
    },
  );

  return axiosHttpClient;
};
